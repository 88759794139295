import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input,
  Directive,
} from '@angular/core';
import { Question } from 'src/app/shared/models/vcall/question';
import { Subscription } from 'rxjs';
import { Answer } from 'src/app/shared/models/application/answer';
import { Application } from 'src/app/shared/models/application/application';
import { TemplateModel } from 'src/app/shared/models/vcall/templateModel';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import {
  UIModalBehavior,
  UiServiceService,
} from 'src/app/shared/services/ui-service.service';

@Directive()
export abstract class BaseQuestionType<T>
  implements OnInit, OnDestroy, AfterViewInit
{
  constructor(
    protected script: ScriptService,
    protected applicationService: ApplicationService,
    protected uiService: UiServiceService = null
  ) {}

  question: Question = null;
  subscriptions: Array<Subscription> = new Array<Subscription>();
  elementQuestionIdTrackerId = {};
  application: Application;
  details: T = Object.create(null);
  answer: Answer = null;
  selectedValue: string;
  originalValue: string;
  medicalAnswers: Answer[];
  questions: Question[][] = new Array<Question[]>();
  answerList: Answer[];
  promptText: string;

  abstract ApplicationChanged(): void;
  abstract QuestionChanged(q: Question): void;
  abstract CanGoNext(): boolean;
  abstract ResetView(): void;

  ngOnInit() {
    this.subscriptions.push(
      this.applicationService.SelectedApplication.subscribe((a) => {
        if (a.id !== 0) {
          this.application = a;

          this.ApplicationChanged();
        }
      })
    );

    this.subscriptions.push(
      this.applicationService.DashboardAnswers.subscribe((a) => {
        this.medicalAnswers = a;
      })
    );
    this.subscriptions.push(
      this.script.ApplicationScript.subscribe((a) => {
        a?.items.map((q) => {
          this.questions.push(q.questions);
        });
      })
    );
    this.subscriptions.push(
      this.applicationService.Answers.subscribe((a) => {
        this.answerList = a;
      })
    );
    this.subscriptions.push(
      this.script.PromptText.subscribe((a) => {
        this.promptText = a;
      })
    );
    this.subscriptions.push(
      this.script.SelectedQuestion.subscribe((q) => {
        this.question = q;
        if (q) {
          // Resetting
          if (this.details === undefined || this.details === null) {
            this.details = Object.create(null);
          }
          Object.keys(q.details).map((k) => (this.details[k] = null));
          // Transposing from new details to the object;
          Object.keys(this.details).map((k) => {
            if (!q.details.hasOwnProperty(this.details[k])) {
              delete this.details[k];
            }
            return this.details;
          });
          Object.keys(q.details).map((k) => (this.details[k] = q.details[k]));
          const applicationValue = this.applicationService.GetValueForAnswerTag(
            this.question.answerTag
          );
          this.answer = this.applicationService.GetAnswerForAnswerTag(
            this.question.answerTag
          );

          this.QuestionChanged(q);

          const a = this.answer ? this.answer.value : null;
          this.selectedValue = a || applicationValue;
          this.originalValue = this.selectedValue;

          if (this.elementQuestionIdTrackerId['ngOnInit'] !== q.id) {
            this.elementQuestionIdTrackerId['ngOnInit'] = q.id;

            // TODO : REVISIT refactor to automate this manual step.
            if (this.answer) {
              // This is where this components broadcasts that it is ok to go next
              // Always attach the raw to the question.answer property
              // This kludgy behavior can go away on ce script when loaded is hydrated with stored answers.
              this.script.UpdateAnswer(this.CanGoNext(), this.answer.value); //
            } else {
              this.script.UpdateAnswer(this.CanGoNext(), null); //
            }
          }
        }
      })
    );
  }

  GetText() {
    return this.promptText;
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.script.SelectedQuestion.subscribe((q) => {
        setTimeout(() => {
          if (this.elementQuestionIdTrackerId['ngAfterviewInit'] !== q?.id) {
            this.elementQuestionIdTrackerId['ngAfterviewInit'] = q?.id;
            this.ResetView();
            this.QuestionChanged(q);
          }
        }, 0);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((a) => a.unsubscribe());
  }

  saveAnswer(verification: boolean, answer: any, answerType?: string) {
    try {
      this.applicationService.RegisterAnswerByTag(
        verification,
        answer,
        this.question?.answerTag,
        null,
        null,
        this.promptText || this.GetText(),
        answerType ? answerType : this.question?.type,
        null,
        null,
        null,
        this.question
      );
    } catch (ex) {
      this.uiService.ModalMessage(
        ex.message,
        'Error saving answers',
        UIModalBehavior.Refresh
      );
    }

    this.script.UpdateAnswer(this.CanGoNext(), answer); //
  }

  safeInterpolate(
    stringToInterpolate: any,
    scope: object = null,
    props: object = null
  ) {
    let result = stringToInterpolate;
    scope = scope || this;
    props = props || new TemplateModel();

    if (props['application'] === undefined) {
      props['application'] = this.application;
    }

    try {
      result = this.uiService.interpolate(stringToInterpolate, scope, props);
    } finally {
    }
    return result;
  }
}

@Directive()
export abstract class NavigationAwareQuestionType<
  T
> extends BaseQuestionType<T> {
  constructor(
    protected script: ScriptService,
    public appService: ApplicationService,
    protected ui: UiServiceService
  ) {
    super(script, appService, ui);
  }

  public abstract Navigating(
    direction: NavigationDirection
  ): NavigationArgument;
}

export class NavigationArgument {
  stopPropagation: boolean;
  reason: string;
}

export enum NavigationDirection {
  Next,
  Previous,
}
