<div
  class="row text-center"
  *ngIf="application && application.id === 0; else content"
>
  <div class="col-12 mt-5">
    <h4 class="p-3">
      <p>The selected application was not found in the verification system. We are automatically importing it from the backend systems. Thanks for
        your patience and sorry for the inconvenience.</p>
    </h4>
  </div>
</div>
<ng-template #content>
  <div class="row text-center" *ngIf="!application">
    <div class="col-12 mt-5">
      <h4 class="p-3">Loading application, please wait...</h4>
    </div>
  </div>
</ng-template>
<div *ngIf="application && appointment && application.id !== 0">
  <div class="main-upper-cnt mt-2">
    <div class="row">
      <div class="col-7">
        <h4 class="text-uppercase pb-1">
          {{ application.primaryApplicant | fullname }}
          <span class="small-hdr" (click)="downloadPdf()">
            ({{ application.id }})
            <span class="ml-1 text-danger"
              ><i class="fa fa-file-pdf" aria-hidden="true"></i
            ></span>
          </span>
        </h4>
      </div>
      <div
        class="col text-right"
        *ngIf="detailOptions?.canStartVerification !== false"
      >
        <!-- <button
          class="btn btn-sm btn-outline-primary btn-hdr mr-1"
          data-toggle="modal"
          data-target="#select-person-modal"
        >
          Reschedule
        </button> -->
        <button
          class="btn btn-sm btn-primary btn-hdr"
          [disabled]="
            application.id === 0 ||
            appointment.statusName === 'Completed' ||
            appointment.statusName === 'Stop Call' ||
            appointment.statusName === 'Canceled' ||
            appointment.statusName === 'No Show' ||
            !application.isValid
          "
          (click)="startVerification()"
          [appPermission]="'CanAccessStartVcallOrRecall'"
        >
          {{
            appointment.callType === "Recall"
              ? "Start Recall"
              : "Start Verification Call"
          }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <strong>Call Type:</strong>&nbsp;&nbsp;{{ appointment.callType }}
        <span *ngIf="appointment.callType !== 'Group'">
          ({{ appointment.callType | cbduration }})</span
        >
        <span *ngIf="appointment.callType === 'Group'"
          >( {{ application?.groupNumber }} )</span
        >
      </div>
    </div>
    <div class="row">
      <div class="col">
        <strong>Scheduled At:</strong>&nbsp;&nbsp;<span
          class="text-uppercase"
          >{{
            appointment.scheduledDate
              | datewithTimeZone : timeZone : "date-time"
          }}</span
        >
      </div>
    </div>
    <div class="row">
      <div class="col">
        <strong>Express VCall:</strong>&nbsp;&nbsp;{{
          application.isExpress ? "Yes" : "No"
        }}
      </div>
    </div>

    <div class="row">
      <div class="col">
        <strong>Product(s):</strong>&nbsp;&nbsp;
        {{ application.product.description }} ({{
          application.product.function
        }})
      </div>
    </div>
    <div class="row mt-3 pt-3 border-top">
      <div class="col-4">
        <div class="results-label text-primary text-uppercase font-weight-bold">
          Other Applicants
        </div>
        <div class="row" *ngFor="let family of application.family">
          <div class="col">
            {{ family.firstName }} {{ family.lastName }} ({{
              family.relationship | relation
            }}, {{ family.birthDate | age }})<br />
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="results-label text-primary text-uppercase font-weight-bold">
          Contact
        </div>
        <div class="row">
          <div class="col-2 font-weight-bold">Home:</div>
          <div class="col-10">
            {{ application.contact.homePhone | emptyValue : "-" }}
          </div>
        </div>
        <div class="row">
          <div class="col-2 font-weight-bold">Work:</div>
          <div class="col-10">
            {{ application.contact.workPhone | emptyValue : "-" }}
          </div>
        </div>
        <div class="row">
          <div class="col-2 font-weight-bold">Mobile:</div>
          <div class="col-10">
            {{ application.contact.mobilePhone | emptyValue : "-" }}
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="results-label text-primary text-uppercase font-weight-bold">
          Address
        </div>
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col-3 font-weight-bold">House No:</div>
              <div class="col-9">
                {{ application.contact.streetAddress }}
              </div>
            </div>
            <div class="row">
              <div class="col-3 font-weight-bold">City, State:</div>
              <div class="col-9">
                {{ application.contact.city }},
                {{ application.contact.state }}
              </div>
            </div>
            <div class="row">
              <div class="col-3 font-weight-bold">Zip Code:</div>
              <div class="col-9">
                {{ application.contact.zipCode }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a
            class="nav-link active"
            href="#log"
            id="log-tab"
            data-toggle="tab"
            role="tab"
            >Call Log & Notes</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="#policy"
            id="policy-tab"
            data-toggle="tab"
            role="tab"
            >Policy Details</a
          >
        </li>
        <li class="nav-item" *ngIf="appointment.callType === 'Recall'">
          <a
            class="nav-link"
            href="#recall"
            id="recall-tab"
            data-toggle="tab"
            role="tab"
            >Recall Questions</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="#ezappNotes"
            id="recall-tab"
            data-toggle="tab"
            role="tab"
            >EzApp Note</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="#addendumNotes"
            id="addendum-tab"
            data-toggle="tab"
            role="tab"
            >Addendum Notes</a
          >
        </li>
        <li class="nav-item" *ngIf="enableNotifications">
          <a
            class="nav-link"
            href="#smsNotifications"
            id="sms-notification-tab"
            data-toggle="tab"
            role="tab"
            >SMS Notifications</a
          >
        </li>
      </ul>

      <div class="tab-content bg-white" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="log"
          role="tabpanel"
          aria-labelledby="log-tab"
        >
          <app-notes-detail [detailOptions]="detailOptions"></app-notes-detail>
        </div>
        <div
          class="tab-pane fade border-bottom border-right border-left"
          id="policy"
          role="tabpanel"
          aria-labelledby="policy-tab"
        >
          <app-policy-details></app-policy-details>
        </div>
        <div
          class="tab-pane fade border-bottom border-right border-left"
          id="recall"
          role="tabpanel"
          aria-labelledby="recall-tab"
          *ngIf="appointment.callType === 'Recall'"
        >
          <app-recall-questions></app-recall-questions>
        </div>
        <div
          class="tab-pane fade border-bottom border-right border-left"
          id="ezappNotes"
          role="tabpanel"
          aria-labelledby="recall-tab"
        >
          <app-ezapp-notes></app-ezapp-notes>
        </div>
        <div
          class="tab-pane fade border-bottom border-right border-left"
          id="addendumNotes"
          role="tabpanel"
          aria-labelledby="addendum-tab"
        >
          <app-addendum-notes></app-addendum-notes>
        </div>
        <div
          class="tab-pane fade border-bottom border-right border-left"
          id="smsNotifications"
          role="tabpanel"
          aria-labelledby="sms-notification-tab"
        >
          <app-sms-notifications></app-sms-notifications>
        </div>
      </div>
    </div>
  </div>

  <!-- <app-online-applicants></app-online-applicants> -->
  <ng-container *ngIf="detailOptions?.canStartVerification !== false">
    <app-voice-mail-modal></app-voice-mail-modal>
  </ng-container>
</div>
